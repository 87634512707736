import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { FormatDate, Link, useI18next } from "../i18n"
import { Image, ImageData } from "../Layout/Components"

import sitemap from "../sitemap"

export type PostData = {
  title: string
  date: string
  details: {
    uid: string
    authors: [
      {
        id: string
        title: string
        details: {
          titolo: string
        }
      }
    ]
    excerpt: string
    featuredImage: ImageData
    layout: {
      highlighted: boolean
      minor: boolean
    }
  }
  commentCount: string
}

type Props = {
  className?: string
  relatedPosts: PostData[]
  allPosts: PostData[]
  articlesType?: "all" | "related"
  articlesCount?: 0 | 1 | 3 | 5 // 0 = All
  exceptPostUid?: string
  noTitle?: boolean
  random?: boolean
  display?: "random" | "compact" | "full" // default = "compact"
}

export const CommunityArticles: React.FC<Props> = ({ className, relatedPosts, allPosts, articlesType, articlesCount, exceptPostUid, noTitle, display }) => {
  const { t } = useI18next()

  display = display || "compact"
  let posts = articlesType == "all" ? allPosts : relatedPosts
  // posts = [...posts, ...posts, ...posts, ...posts, ...posts, ...posts, ...posts, ...posts, ...posts]
  // const shownArticles = articlesCount == posts.length ? 3 : articlesCount == 0 ? posts.length : articlesCount
  const shownArticles = articlesCount == null || articlesCount == 0 ? posts.length : articlesCount

  // eventually filter
  if (exceptPostUid) {
    posts = posts.filter((post) => post.details.uid != exceptPostUid)
  }

  // If no posts do not render
  if (posts.length == 0) return null

  const ArticleDetailed: React.FC<{ post: PostData; width?: number; height?: number; highlighted: boolean }> = ({ post, width, height, highlighted }) => {
    if (post == undefined) return null
    return (
      <Link
        to={"post:" + post.details.uid}
        className={`col col--f${width || 12} row-span--${height || 1} card__article card__article--big ${highlighted ? "card__article--highlighted" : ""}`}
      >
        <Image file={post.details.featuredImage} type="cover" className="card__article-image" />
        <div className="card__article-content">
          <div className="card__article-author">
            {post.details.authors.map((author, i: number) => (
              <span key={i}>
                {author.title}
                {i < post.details.authors.length - 2 ? t(",") : ""}
                {i == post.details.authors.length - 2 ? t(" e") : ""}{" "}
              </span>
            ))}
          </div>
          <h3 className="card__article-title" dangerouslySetInnerHTML={{ __html: post.title }} />
          <div className="card__article-description" dangerouslySetInnerHTML={{ __html: post.details.excerpt }} />
          <div className="card__article-content-background"></div>
          <div className="card__article-cta">
            <button className="btn btn--ghost">{t("Leggi di più")}</button>
            <div className="card__article-date">
              <FormatDate date={post.date} />
            </div>
          </div>
        </div>
      </Link>
    )
  }

  const ArticleDetailedVertical: React.FC<{
    post: PostData
    width?: number
    height?: number
    highlighted: boolean
  }> = ({ post, width, height, highlighted }) => {
    if (post == undefined) return null
    return (
      <Link
        to={"post:" + post.details.uid}
        className={`col col--f${width || 12} row-span--${height || 1} card__article card__article--vert card__article--big ${
          highlighted ? "card__article--highlighted" : ""
        }`}
      >
        <Image file={post.details.featuredImage} type="cover" className="card__article-image" />
        <div className="card__article-content">
          <div className="card__article-author">
            {post.details.authors.map((author, i: number) => (
              <span key={i}>
                {author.title}
                {i < post.details.authors.length - 2 ? t(",") : ""}
                {i == post.details.authors.length - 2 ? t(" e") : ""}{" "}
              </span>
            ))}
          </div>
          <h3 className="card__article-title" dangerouslySetInnerHTML={{ __html: post.title }} />
          <div className="card__article-description" dangerouslySetInnerHTML={{ __html: post.details.excerpt }} />
          <div className="card__article-content-background"></div>
          <div className="card__article-cta">
            <button className="btn btn--ghost">{t("Leggi di più")}</button>
            <div className="card__article-date">
              <FormatDate date={post.date} />
            </div>
          </div>
        </div>
      </Link>
    )
  }

  const Article: React.FC<{ post: PostData; width?: number; height?: number; highlighted: boolean }> = ({ post, width, height, highlighted }) => {
    if (post == undefined) return null
    return (
      <Link
        to={"post:" + post.details.uid}
        className={`col col--f${width || 6} row-span--${height || 1}  card__article card__article--small ${highlighted ? "card__article--highlighted" : ""}`}
      >
        <div className="card__article-content">
          <div className="card__article-author">
            {post.details.authors.map((author, i: number) => (
              <span key={i}>
                {author.title}
                {i < post.details.authors.length - 2 ? t(",") : ""}
                {i == post.details.authors.length - 2 ? t(" e") : ""}{" "}
              </span>
            ))}
          </div>
          <h3 className="card__article-title" dangerouslySetInnerHTML={{ __html: post.title }} />
          <div className="card__article-description" dangerouslySetInnerHTML={{ __html: post.details.excerpt }} />
          <div className="card__article-content-background"></div>
          <div className="card__article-cta">
            <button className="btn btn--ghost">{t("Leggi di più")}</button>
            <div className="card__article-date">
              <FormatDate date={post.date} />
            </div>
          </div>
        </div>
      </Link>
    )
  }

  //-----------------------------------------------------------------------------------------------
  // Possible distributions

  const articleDistributions = {
    compact: [
      { width: 12, height: 1, highlighted: false, component: ArticleDetailed },
      { width: 6, height: 1, highlighted: false, component: Article },
      { width: 6, height: 1, highlighted: false, component: Article },
      { width: 6, height: 1, highlighted: false, component: ArticleDetailedVertical },
      { width: 6, height: 1, highlighted: false, component: ArticleDetailedVertical },
    ],
    full: [
      { width: 12, height: 1, highlighted: false, component: ArticleDetailedVertical },
      { width: 12, height: 1, highlighted: false, component: ArticleDetailed },
      { width: 6, height: 2, highlighted: false, component: ArticleDetailedVertical },
    ],
    minor: [{ width: 6, height: 1, highlighted: false, component: Article }],
    test: [
      { width: 6, height: 2, highlighted: true, component: ArticleDetailedVertical },
      { width: 6, height: 1, highlighted: false, component: Article },
      { width: 6, height: 2, highlighted: false, component: ArticleDetailedVertical },
      { width: 12, height: 1, highlighted: false, component: ArticleDetailed },
      { width: 6, height: 1, highlighted: false, component: Article },
      { width: 6, height: 1, highlighted: false, component: Article },
    ],
  }

  const randomDistribution = [
    { width: 12, height: 1, highlighted: true, component: ArticleDetailedVertical },
    { width: 12, height: 1, highlighted: false, component: ArticleDetailedVertical },
    { width: 12, height: 1, highlighted: false, component: ArticleDetailedVertical },
    { width: 6, height: 2, highlighted: true, component: ArticleDetailedVertical },
    { width: 6, height: 2, highlighted: false, component: ArticleDetailedVertical },
    { width: 6, height: 2, highlighted: false, component: ArticleDetailedVertical },
    { width: 12, height: 1, highlighted: true, component: ArticleDetailed },
    { width: 12, height: 1, highlighted: false, component: ArticleDetailed },
    { width: 12, height: 1, highlighted: false, component: ArticleDetailed },
    { width: 6, height: 1, highlighted: true, component: Article },
    { width: 6, height: 1, highlighted: false, component: Article },
    { width: 6, height: 1, highlighted: false, component: Article },
    { width: 6, height: 1, highlighted: false, component: Article },
    { width: 6, height: 1, highlighted: false, component: Article },
    { width: 6, height: 1, highlighted: false, component: Article },
  ]

  //-----------------------------------------------------------------------------------------------

  // Render all the articles
  let articles = []
  for (let i = 0; i < Math.min(shownArticles, posts.length); i++) {
    let currentDistribution: { width: number; height: number; highlighted: boolean; component: any }
    switch (display) {
      case "random":
        currentDistribution = { ...randomDistribution[Math.floor(Math.random() * randomDistribution.length)] }
        break
      case "compact":
        currentDistribution = {
          ...articleDistributions["compact"][i % articleDistributions["compact"].length],
          highlighted: posts[i].details.layout.highlighted,
        }
        break
      case "full":
        // after rendering the first posts as dictated by "full" distribution, consider also rendering possible "minor" posts
        currentDistribution =
          posts[i].details.layout.minor && i > articleDistributions["full"].length - 1
            ? {
                ...articleDistributions["minor"][i % articleDistributions["minor"].length],
                highlighted: posts[i].details.layout.highlighted,
              }
            : {
                ...articleDistributions["full"][Math.min(i, articleDistributions["full"].length - 1)],
                highlighted: posts[i].details.layout.highlighted,
              }
        break
    }

    const Component = currentDistribution.component
    articles.push(
      <Component post={posts[i]} width={currentDistribution.width} height={currentDistribution.height} highlighted={currentDistribution.highlighted} key={i} />
    )
  }

  return (
    <div className={`row row--full pb-l ${className}`}>
      {noTitle ? null : (
        <div className="row row--center row--flex mb-none">
          <h2 className="heading heading-title mb-none">Gli articoli del nostro Blog</h2>
          <Link to={sitemap.posts.it} className="btn btn--ghost">
            vai al blog
          </Link>
        </div>
      )}
      <div className="row row--inner row--gap-medium col--gap-medium">{articles}</div>
    </div>
  )
}

export default CommunityArticles

// Query fragment to include in graphql
export const query = graphql`
  fragment post on Wordpress_Post {
    title
    date
    details {
      uid
      excerpt
      layout {
        highlighted
        minor
      }
      featuredImage {
        ...imageData
      }
      authors {
        ... on Wordpress_Person {
          id
          title
          details {
            titolo
          }
        }
      }
    }
    commentCount
  }
  fragment relatedPosts on Wordpress {
    relatedPosts: posts(where: { language: $languageCode, orderby: { field: DATE, order: DESC }, tagSlugIn: $tags }, first: $relatedPostsLimit) {
      nodes {
        ...post
      }
    }
  }
  fragment recentPosts on Wordpress {
    recentPosts: posts(where: { language: $languageCode, orderby: { field: DATE, order: DESC } }, first: 5) {
      nodes {
        ...post
      }
    }
  }
`
