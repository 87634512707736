import React from "react"
import { graphql } from "gatsby"
import GridLayout from "../Layout/GridLayout"
import { useI18next } from "../i18n"

import { Breadcrumbs } from "../Layout/Components"
import Blog, { PostData, CategoryData, TagData } from "../rows/Blog"

import { Seo } from "../seo"
import siteMap from "../sitemap"

export default (props) => {
  const { t } = useI18next()
  const tag = props.data.wordPress.tag

  const posts: PostData[] = props.data.wordPress.tag.posts.nodes
  const recentPosts: PostData[] = props.data.wordPress.recentPosts.nodes
  const categories: CategoryData[] = props.data.wordPress.categories.nodes
  const tags: TagData[] = props.data.wordPress.tags.nodes

  //console.log("Props: ", props)
  //console.log("Context: ", props.pageContext)

  return (
    <GridLayout>
      <Seo title={tag.name} description={tag.description} image={tag.details.featuredImage?.imageFile.publicURL} />
      <Breadcrumbs
        data={[{ to: "/", label: t("Home") }, { to: siteMap.posts.it, label: t("Blog") }, { label: tag.name }]}
      />

      <Blog
        title={tag.name}
        description={tag.description}
        image={tag.details.featuredImage}
        posts={posts}
        categories={categories}
        tags={tags}
        allPosts={recentPosts}
      />
    </GridLayout>
  )
}

//query($id: ID!, $tags: [String!]) {
export const query = graphql`
  query($id: ID!, $languageCode: Wordpress_LanguageCodeFilterEnum!) {
    wordPress {
      tag(id: $id) {
        description
        name
        details {
          uid
          featuredImage {
            ...imageData
          }
        }
        posts(where: { orderby: { field: DATE, order: DESC } }, first: 1000) {
          nodes {
            ...post
          }
        }
      }
      ...recentPosts
      ...sidebarData
    }
  }
`
