import React from "react"
import { graphql } from "gatsby"
import GridLayout from "../Layout/GridLayout"
import { FormatDate, useI18next, Link } from "../i18n"

import { ImageData, Image, Icon } from "../Layout/Components"
import { DynamicRow, RowData } from "./DynamicRow"
import HeroBlog from "./HeroBlog"
import WPComments, { CommentData } from "./WPComments"
import CommunityArticles from "./CommunityArticles"

import { Seo } from "../seo"
import { decodeHTMLEntities } from "../Layout/utils/utils"
import Sidebar from "./Sidebar"

export type PostData = {
  title: string
  id: string
  databaseId: number
  date: string
  modified: string
  slug: string
  language: {
    slug: string
  }
  translations: [
    {
      slug: string
      language: {
        slug: string
      }
    }
  ]
  seo: {
    title: string
    metaDesc: string
  }
  categories: {
    nodes: [
      {
        name: string
        slug: string
        details: { uid: string }
      }
    ]
  }
  tags: {
    nodes: [
      {
        name: string
        slug: string
      }
    ]
  }
  details: {
    uid: string
    authors: [
      {
        id: string
        title: string
        details: {
          ownedCars: string
          bio: string
          titolo: string
          photo: ImageData
        }
      }
    ]
    excerpt: string
    rows: RowData[]
    featuredImage: ImageData
  }
  commentCount: number
  commentStatus: string
  comments: { nodes: CommentData[] }
}

export type CategoryData = {
  slug: string
  name: string
  details: { uid: string }
  language: { slug: string }
  posts: {
    nodes: [
      {
        title: string
        slug: string
        date: string
      }?
    ]
  }
}

export type TagData = {
  slug: string
  name: string
  details: {
    uid: string
    visible: boolean
  }
  posts: {
    nodes: [
      {
        title: string
        slug: string
        date: string
      }?
    ]
  }
}

type Props = {
  title: string
  description: string
  image: ImageData
  relatedPosts: PostData[]
  allPosts: PostData[]
  categories: CategoryData[]
  tags: TagData[]
}

export default ({ title, description, image, posts, allPosts, categories, tags }) => {
  const { t } = useI18next()

  return (
    <>
      <HeroBlog image={image} title={description} category={title} />

      <div className="row row--center pt-none">
        <div className="blog__post blog__post--list">
          <CommunityArticles allPosts={posts} relatedPosts={[]} articlesType="all" articlesCount={0} noTitle display="full" />
        </div>
        <Sidebar allPosts={allPosts} categories={categories} tags={tags} />
      </div>
    </>
  )
}
